<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Solicitudes Transporte Autorizaciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tep</li>
                  <li class="breadcrumb-item active">Nacionales</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">
                    Solicitud Transporte Autorizaciones
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2">
                <div class="row">
                  <div class="col-md-11">
                    <h5>Autorizaciones</h5>
                  </div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <!-- SE LLAMAN BOTONES ACA -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-bordered table-striped table-hover table-sm">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>
                        # Solicitud
                        <input
                          v-model="filtros.id_solicitud"
                          type="number"
                          class="form-control form-control-sm"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>Dirección Origen</th>
                      <th>Dirección Destino</th>
                      <th>Tipo Vehículo</th>
                      <th>
                        Tipo Autorización
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.tipo_autorizacion"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="autorizacion in listasForms.tipo_autorizaciones"
                            :key="autorizacion.numeracion"
                            :value="autorizacion.numeracion"
                          >
                            {{ autorizacion.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>Inicio Solicitud</th>
                      <th>Fecha Autorización</th>
                      <th>Cant. Pasajeros</th>
                      <th>
                        Usuario
                        <input
                          v-model="filtros.user"
                          type="text"
                          class="form-control form-control-sm"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in autorizaciones.data" :key="item.id">
                      <td>
                        {{ item.id }}
                      </td>
                      <td class="text-center">
                        <button
                          type="button"
                          class="btn btn-sm bg-light"
                          data-toggle="modal"
                          data-target="#modal-form-detalle-tepautorizacion"
                          style="cursor: pointer"
                          v-if="
                            $store.getters.can(
                              'tep.autorizaciones.modal_detalle'
                            )
                          "
                          @click="llenarModal(item)"
                        >
                          {{ item.solicitud ? item.solicitud.id : "" }}
                        </button>
                      </td>
                      <td>{{ item.solicitud.direccion_origen }}</td>
                      <td>{{ item.solicitud.direccion_destino }}</td>
                      <td class="text-nowrap">{{ item.solicitud.tipo_vehiculo.nombre }}</td>
                      <td class="text-nowrap">{{ item.nTipoAutorizacion }}</td>
                      <td class="text-nowrap">{{ item.solicitud ? item.solicitud.fecha_hora_inicio_servicio : '' }}</td>
                      <td class="text-nowrap">{{ item.fecha_autorizacion }}</td>
                      <td class="text-center">
                        {{
                          item.solicitud && item.solicitud.tep_solicitud_pasajero 
                          ? item.solicitud.tep_solicitud_pasajero.length : '' 
                        }}
                      </td>
                      <td>
                        {{
                          item.solicitud && item.solicitud.user
                          ? item.solicitud.user.name : ''
                        }}
                      </td>
                      <td class="text-center">
                        <span 
                          class="badge badge-success"
                          :class="
                            item.estado == 1
                            ? 'badge-success'
                            : item.estado == 2
                            ? 'badge-warning'
                            : item.estado == 3
                            ? 'badge-danger'
                            : 'badge-secundary'
                          "
                        >
                          {{ item.nEstado }}
                        </span>
                      </td>
                      <td class="text-right">
                        <div class="btn-group">
                          <button type="button" 
                            class="btn btn-sm bg-olive"
                            v-if="
                              $store.getters.can('tep.autorizaciones.autorizacion')
                              && item.estado == 2
                            "
                            data-toggle="modal"
                            data-target="#modal-form-tep-estado"
                            @click="
                              llenarModalEstado(
                                item.id,
                                item.estado,
                                item.tep_solicitud_id,
                                item.solicitud.fecha_hora_inicio_servicio,
                                item.solicitud.lat_origen,
                                item.solicitud.long_origen
                              )
                            "
                          >
                            <i class="fas fa-signature"></i>
                          </button>
                          <!-- <button type="button" 
                            class="btn btn-sm bg-navy"
                            data-toggle="modal"
                            data-target="#modal-form-detalle-tepautorizacion"
                            v-if="$store.getters.can('tep.autorizaciones.modal_detalle')"
                            @click="llenarModal(item)"
                          >
                            <i class="fas fa-edit"></i>
                          </button> -->
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="autorizaciones.total">
                  <p>
                    Mostrando del <b>{{ autorizaciones.from }}</b> al
                    <b>{{ autorizaciones.to }}</b> de un total:
                    <b>{{ autorizaciones.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="autorizaciones"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <TepAutorizacionDetalle ref="TepAutorizacionDetalle" />
    <TepAutorizacionEstado ref="TepAutorizacionEstado" />
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import pagination from "laravel-vue-pagination";
import TepAutorizacionDetalle from "./TepAutorizacionDetalle";
import TepAutorizacionEstado from "./TepAutorizacionEstado";
export default {
  name: "TepAutorizacionIndex",
  components: {
    Loading,
    pagination,
    TepAutorizacionDetalle,
    TepAutorizacionEstado,
  },
  data() {
    return {
      cargando: false,
      filtros: {
        id_solicitud: null,
        usuario: this.$store.state.user.id,
        tipo_autorizacion: null,
        estado: null,
        user: null,
      },
      autorizaciones: {},
      listasForms: {
        tipo_autorizaciones: [],
        estados: [],
      },
    };
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("/api/tep/AutorizacionesTransporte?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.autorizaciones = response.data;
          this.cargando = false;
        });
    },

    getEstados() {
      axios.get("/api/lista/87").then((response) => {
        this.listasForms.estados = response.data;
      });
    },
    getTipoAutorizacion() {
      axios.get("/api/lista/86").then((response) => {
        this.listasForms.tipo_autorizaciones = response.data;
      });
    },
    llenarModal(item) {
      if (this.$store.getters.can("tep.autorizaciones.modal_detalle")) {
        this.$refs.TepAutorizacionDetalle.llenar_modal(item);
      }
    },
    llenarModalEstado(id, estado, solicitud_id, fecha_solicitud, lat, long) {
      if (this.$store.getters.can("tep.solicitudes.detalle")) {
        this.$refs.TepAutorizacionEstado.llenar_modal_estado(
          id,
          estado,
          solicitud_id,
          fecha_solicitud,
          lat,
          long
        );
      }
    },
    getMsg() {
      if (this.$route.params.msg) {
        this.$swal({
          icon: "success",
          title: this.$route.params.msg,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },
  },

  mounted() {
    this.getIndex();
    this.getEstados();
    this.getTipoAutorizacion();
    // this.getMsg();
  },
};
</script>
