<template>
  <div class="hold-transition">
    <div
      class="modal fade"
      id="modal-form-detalle-tepautorizacion"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left">
            <h4 class="modal-title text-white">Detalle Autorizacion</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0">
            <div class="row">
              <div class="col-md-12">
                <ul class="nav nav-tabs m-4" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="tab-Autorizacion"
                      data-toggle="tab"
                      href="#Autorizacion"
                      >Autorizacion</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="tab-Solicitud"
                      data-toggle="tab"
                      href="#Solicitud"
                      >Solicitud
                    </a>
                  </li>
                </ul>
                <div class="tab-content m-4" id="myTabContent">
                  <div class="tab-pane fade active show" id="Autorizacion">
                    <div class="card-body">
                      <!-- Detalle de la Autorizacion -->
                      <div class="row">
                        <div class="col-md-12">
                          <div
                            class="position-relative p-3 m-2 bg-light"
                            style="font-size: 0.85em"
                          >
                            <div class="ribbon-wrapper ribbon-lg">
                              <div
                                class="ribbon"
                                :class="
                                  data_autorizacion.estado == 1
                                    ? 'badge-success'
                                    : data_autorizacion.estado == 2
                                    ? 'badge-warning'
                                    : data_autorizacion.estado == 3
                                    ? 'badge-danger'
                                    : 'badge-secondary'
                                "
                              >
                                <small>{{ data_autorizacion.nEstado }}</small>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-sm-1">
                                <div class="description-block">
                                  <h5 class="description-header">
                                    {{ data_autorizacion.id }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    ># Solicitud</span
                                  >
                                </div>
                              </div>
                              <div class="col-sm-3">
                                <div class="description-block">
                                  <h5 class="description-header">
                                    {{
                                      data_autorizacion.user
                                        ? data_autorizacion.user.name
                                        : ""
                                    }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Usuario</span
                                  >
                                </div>
                              </div>
                              <div class="col-sm-3">
                                <div class="description-block">
                                  <h5 class="description-header">
                                    {{
                                      data_autorizacion.fecha_autorizacion
                                        ? data_autorizacion.fecha_autorizacion
                                        : ""
                                    }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Fecha Autorizacion</span
                                  >
                                </div>
                              </div>
                              <div class="col-sm-2">
                                <div class="description-block">
                                  <h5 class="description-header">
                                    {{ data_autorizacion.nTipoAutorizacion }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Tipo Autorizacion</span
                                  >
                                </div>
                              </div>
                              <div class="col-sm-10">
                                <div class="description-block">
                                  <h5 class="description-header">
                                    <span>
                                      {{
                                        data_autorizacion.observaciones
                                      }}</span
                                    >
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Observaciones</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane"
                    id="Solicitud"
                    v-if="data_autorizacion.solicitud != null"
                  >
                    <div class="row">
                      <div class="col-md-6">
                        <div class="card">
                          <div class="card-header">
                            <h5 class="card-title">Datos Solicitud</h5>
                            <div class="card-tools">
                              <span
                                class="badge"
                                :class="
                                  data_autorizacion.solicitud.estado == 1
                                    ? 'badge-warning'
                                    : data_autorizacion.solicitud.estado == 2
                                    ? 'badge-success'
                                    : data_autorizacion.solicitud.estado == 3
                                    ? 'badge-primary'
                                    : data_autorizacion.solicitud.estado == 4
                                    ? 'badge-danger'
                                    : data_autorizacion.solicitud.estado == 5
                                    ? 'badge-primary'
                                    : data_autorizacion.solicitud.estado == 6
                                    ? 'badge-success'
                                    : 'badge-danger'
                                "
                                >{{ data_autorizacion.solicitud.nEstado }}</span
                              >
                            </div>
                          </div>
                          <div class="card-body">
                            <!-- Detalle de la Solicitud -->
                            <div class="row">
                              <p class="col-sm-6 text-bold"># Solicitud:</p>
                              <p class="col-sm-6 description-header">
                                {{ data_autorizacion.solicitud.id }}
                              </p>
                            </div>
                            <div class="row">
                              <p class="col-sm-6 text-bold">Tipo Vehiculo:</p>
                              <p class="col-sm-6 description-header">
                                {{
                                  data_autorizacion.solicitud.tipo_vehiculo
                                    ? data_autorizacion.solicitud.tipo_vehiculo
                                        .nombre
                                    : ""
                                }}
                              </p>
                            </div>
                            <div class="row">
                              <p class="col-sm-6 text-bold">Fecha Inicio</p>
                              <p class="col-sm-6 description-header">
                                {{
                                  data_autorizacion.solicitud
                                    .fecha_hora_inicio_servicio
                                }}
                              </p>
                            </div>
                            <div class="row">
                              <p class="col-sm-6 text-bold">Fecha Fin</p>
                              <p class="col-sm-6 description-header">
                                {{
                                  data_autorizacion.solicitud
                                    .fecha_hora_fin_servicio
                                }}
                              </p>
                            </div>

                            <div class="row">
                              <p class="col-sm-6 text-bold">Origen</p>
                              <p
                                class="col-sm-6 description-header"
                                v-if="
                                  data_autorizacion.solicitud.direccion_origen
                                "
                              >
                                {{
                                  data_autorizacion.solicitud.direccion_origen
                                }}
                              </p>
                            </div>
                            <div class="row">
                              <p class="col-sm-6 text-bold">Destino</p>
                              <p
                                class="col-sm-6 description-header"
                                v-if="
                                  data_autorizacion.solicitud.direccion_destino
                                "
                              >
                                {{
                                  data_autorizacion.solicitud.direccion_destino
                                }}
                              </p>
                            </div>
                            <div class="row">
                              <p class="col-sm-6 text-bold">Ruta</p>
                              <p
                                class="col-sm-6 description-header"
                                v-if="data_autorizacion.solicitud.ruta"
                              >
                                {{ data_autorizacion.solicitud.ruta }}
                              </p>
                            </div>
                            <div class="row">
                              <p class="col-sm-6 text-bold">Motivo</p>
                              <p
                                class="col-sm-6 description-header"
                                v-if="data_autorizacion.solicitud.motivo"
                              >
                                {{ data_autorizacion.solicitud.motivo }}
                              </p>
                            </div>
                            <div
                              class="row"
                              v-if="
                                data_autorizacion.solicitud.tipo_transporte !==
                                  1
                              "
                            >
                              <p class="col-sm-6 text-bold">
                                Estado Inspeccion
                              </p>
                              <p
                                class="col-sm-6 description-header"
                                v-if="
                                  data_autorizacion.solicitud.nEstadoInspeccion
                                "
                              >
                                {{
                                  data_autorizacion.solicitud.nEstadoInspeccion
                                }}
                              </p>
                            </div>
                            <div
                              class="row"
                              v-if="
                                data_autorizacion.solicitud.tipo_transporte !==
                                  1
                              "
                            >
                              <p class="col-sm-6 text-bold">
                                Tipo Operacion
                              </p>
                              <p
                                class="col-sm-6 description-header"
                                v-if="
                                  data_autorizacion.solicitud.nEstadoOperacion
                                "
                              >
                                {{
                                  data_autorizacion.solicitud.nEstadoOperacion
                                }}
                              </p>
                            </div>
                            <div
                              class="row"
                              v-if="
                                data_autorizacion.solicitud.tipo_transporte !==
                                  1
                              "
                            >
                              <p class="col-sm-6 text-bold">
                                Sitio
                              </p>
                              <p
                                class="col-sm-6 description-header"
                                v-if="data_autorizacion.solicitud.sitio"
                              >
                                {{ data_autorizacion.solicitud.sitio.nombre }}
                              </p>
                            </div>
                            <div
                              class="row"
                              v-if="
                                data_autorizacion.solicitud.tipo_transporte !==
                                  1
                              "
                            >
                              <p class="col-sm-6 text-bold">
                                ID Solicitud Padre
                              </p>
                              <p
                                class="col-sm-6 description-header"
                                v-if="
                                  data_autorizacion.solicitud
                                    .tep_solicitud_padre_id
                                "
                              >
                                {{
                                  data_autorizacion.solicitud
                                    .tep_solicitud_padre_id
                                }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="card">
                          <div class="card-header">
                            <h5 class="card-title">
                              Pasajeros
                            </h5>
                            <div class="card-tools"></div>
                          </div>
                          <div class="card-body">
                            <table
                              id="califaciones"
                              class="table table-bordered table-striped table-hover table-sm"
                            >
                              <thead>
                                <tr>
                                  <th>Pasajero</th>
                                  <th>Numero Documento</th>
                                  <th>Telefono</th>
                                </tr>
                              </thead>
                              <tbody
                                v-if="
                                  data_autorizacion.solicitud
                                    .tep_solicitud_pasajero
                                "
                              >
                                <tr
                                  v-for="item in data_autorizacion.solicitud
                                    .tep_solicitud_pasajero"
                                  :key="item.id"
                                >
                                  <td>{{ item.nombres }}</td>
                                  <td>{{ item.numero_documento }}</td>
                                  <td>{{ item.telefono }}</td>
                                </tr>
                              </tbody>
                              <tbody v-else></tbody>
                            </table>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header">
                            <h5 class="card-title">
                              Datos contables
                            </h5>
                            <div class="card-tools"></div>
                          </div>
                          <div class="card-body">
                            <table
                              class="table table-bordered table-striped table-hover table-sm"
                            >
                              <thead>
                                <tr>
                                  <th align="center">Tipo presupuesto</th>
                                  <th align="center">Responsable</th>
                                  <th align="center">Numero</th>
                                  <th align="center">Porcentaje</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="item in data_autorizacion.solicitud
                                    .ceco_afe"
                                  :key="item.id"
                                >
                                  <td align="center">
                                    <div v-if="item.tipo_presupuesto == 1">
                                      <h5>
                                        <span class="badge badge-warning"
                                          >Afe</span
                                        >
                                      </h5>
                                    </div>
                                    <div v-else-if="item.tipo_presupuesto == 2">
                                      <h5>
                                        <span class="badge badge-success"
                                          >Ceco</span
                                        >
                                      </h5>
                                    </div>
                                  </td>
                                  <td align="center">
                                    {{ item.funcionario.nombres }}
                                    {{ item.funcionario.apellidos }}
                                  </td>
                                  <td align="center">{{ item.numero }}</td>
                                  <td align="center">{{ item.porcentaje }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-md-center">
                      <div class="col-md-12">
                        <div class="card card-info">
                          <div class="card-header">
                            <h3 class="card-title">
                              Vehiculo y Conductor
                            </h3>
                          </div>
                          <div class="card-body">
                            <!-- Vehículo -->
                            <div class="row">
                              <div
                                class="
                                col-sm-6
                                d-flex
                                align-items-stretch
                                flex-column
                              "
                                v-if="
                                  data_autorizacion.solicitud.vehiculo_id !=
                                    null
                                "
                              >
                                <div class="card bg-white d-flex flex-fill">
                                  <div
                                    class="card-header text-muted border-bottom-0"
                                  >
                                    Vehículo
                                  </div>
                                  <div class="card-body pt-0">
                                    <div class="row">
                                      <div
                                        :class="
                                          data_autorizacion.solicitud.vehiculo
                                            .link_fotografia
                                            ? 'col-md-7'
                                            : 'col-md-10'
                                        "
                                      >
                                        <h2 class="lead">
                                          <b>{{
                                            data_autorizacion.solicitud.vehiculo
                                              .placa
                                          }}</b>
                                        </h2>
                                        <p
                                          class="text-muted text-sm"
                                          v-if="
                                            data_autorizacion.solicitud
                                              .transportadora
                                          "
                                        >
                                          <b>Transportadora: </b>
                                          {{
                                            data_autorizacion.solicitud
                                              .transportadora.razon_social
                                          }}
                                        </p>
                                        <ul class="ml-4 mb-0 fa-ul text-muted">
                                          <li class="small">
                                            <span class="fa-li"
                                              ><i class="fas fa-lg fa-car"></i
                                            ></span>
                                            Tipo Vehículo:
                                            {{
                                              data_autorizacion.solicitud
                                                .tipo_vehiculo.nombre
                                            }}
                                          </li>
                                        </ul>
                                      </div>
                                      <div
                                        class="col-md-5 text-center"
                                        v-if="
                                          data_autorizacion.solicitud.vehiculo
                                            .link_fotografia
                                        "
                                      >
                                        <img
                                          :src="
                                            uri_docs +
                                              data_autorizacion.solicitud
                                                .vehiculo.link_fotografia
                                          "
                                          alt="Vehículo"
                                          class="
                                          img-bordered img-rounded img-fluid
                                        "
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                v-else
                                class="
                                col-12 col-sm-6 col-md-6
                                d-flex
                                align-items-stretch
                                flex-column
                              "
                              >
                                <div
                                  class="small-box bg-secondary"
                                  style="min-height: 100px"
                                >
                                  <div class="inner">
                                    <h4>No hay vehiculo</h4>
                                  </div>
                                  <div class="icon">
                                    <i class="fas fa-car"></i>
                                  </div>
                                </div>
                              </div>
                              <!-- Conductor -->
                              <div
                                class="
                                col-sm-6
                                d-flex
                                align-items-stretch
                                flex-column
                              "
                                v-if="
                                  data_autorizacion.solicitud.conductor_id !=
                                    null
                                "
                              >
                                <div class="card bg-white d-flex flex-fill">
                                  <div
                                    class="card-header text-muted border-bottom-0"
                                  >
                                    Conductor
                                  </div>
                                  <div class="card-body pt-0">
                                    <div class="row">
                                      <div
                                        :class="
                                          data_autorizacion.solicitud.conductor
                                            .link_fotografia
                                            ? 'col-md-7'
                                            : 'col-md-10'
                                        "
                                      >
                                        <h2 class="lead">
                                          <b
                                            >{{
                                              data_autorizacion.solicitud
                                                .conductor.nombres
                                            }}
                                            {{
                                              data_autorizacion.solicitud
                                                .conductor.apellidos
                                            }}
                                          </b>
                                        </h2>
                                        <p
                                          v-if="
                                            data_autorizacion.solicitud.encuesta
                                          "
                                          class="text-muted text-sm"
                                        >
                                          <b>Calificación: </b>
                                          {{
                                            data_autorizacion.solicitud.encuesta
                                              .calificacion
                                          }}
                                          <i class="fas fa-star"></i>
                                        </p>
                                        <p v-else class="text-muted text-sm">
                                          <b>Calificación: </b>
                                          Sin calificación
                                        </p>
                                        <p class="text-muted text-sm">
                                          <b>Documento: </b>
                                          {{
                                            data_autorizacion.solicitud
                                              .conductor.numero_documento
                                          }}
                                        </p>
                                        <ul class="ml-4 mb-0 fa-ul text-muted">
                                          <li class="small">
                                            <span class="fa-li"
                                              ><i
                                                class="fas fa-lg fa-envelope"
                                              ></i
                                            ></span>
                                            E-mail:
                                            {{
                                              data_autorizacion.solicitud
                                                .conductor.email
                                            }}
                                          </li>
                                          <li class="small">
                                            <span class="fa-li"
                                              ><i
                                                class="fas fa-lg fa-mobile"
                                              ></i
                                            ></span>
                                            Celular Principal:
                                            {{
                                              data_autorizacion.solicitud
                                                .conductor.celular_principal
                                            }}
                                          </li>
                                          <li class="small">
                                            <span class="fa-li"
                                              ><i class="fas fa-lg fa-phone"></i
                                            ></span>
                                            Celular Alterno:
                                            {{
                                              data_autorizacion.solicitud
                                                .conductor.celular_alterno
                                            }}
                                          </li>
                                        </ul>
                                      </div>
                                      <div
                                        class="col-md-5 text-center"
                                        v-if="
                                          data_autorizacion.solicitud.conductor
                                            .link_fotografia
                                        "
                                      >
                                        <img
                                          :src="
                                            uri_docs +
                                              data_autorizacion.solicitud
                                                .conductor.link_fotografia
                                          "
                                          alt="Conductor"
                                          class="
                                          img-bordered img-rounded img-fluid
                                        "
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                v-else
                                class="
                                col-12 col-sm-6 col-md-6
                                d-flex
                                align-items-stretch
                                flex-column
                              "
                              >
                                <div
                                  class="small-box bg-secondary"
                                  style="min-height: 100px"
                                >
                                  <div class="inner">
                                    <h4>No hay conductor</h4>
                                  </div>
                                  <div class="icon">
                                    <i class="fas fa-user"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-else class="tab-pane" id="Solicitud">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12">
                          <h2 class="lead">
                            <b><span>No tiene una solicitud asignada</span></b>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-between"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TepAutorizacionDetalle",
  components: {},
  data() {
    return {
      data_autorizacion: {},
      autorizacion_id: null,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  methods: {
    async llenar_modal(item) {
      this.data_autorizacion = item;
    },
  },
};
</script>
